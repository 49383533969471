
/*       #topmar{min-height:370px;background:#fff;position:relative;}*/
#partners {
  background: #e9f6fb;
  //   overflow: hidden;
  //   margin-top: 2px;
}
.featured_emerging_artist_copy {
  overflow: hidden;
}

// #header_right {
//   float: right;
//   padding: 13px 12px 0;
//   background: #0096c3;
//   -moz-box-shadow: -1px -1px 4px rgba(0, 0, 0, 0.1);
//   -webkit-box-shadow: -1px -1px 4px rgba(0, 0, 0, 0.1);
//   box-shadow: -1px -1px 4px rgba(0, 0, 0, 0.1);
//   height: 100%;
// }

// #partners_container {line-height:75px;}
// #partners_container img{display:inline; margin-right: 40px; vertical-align:middle; }
// #partners_container img:last-child{margin-right:0px;}

.bg-light-blue {
  background: #0096c3;
}
html {
  scroll-behavior: smooth;
}
.land {
  scroll-margin-top: 4rem;
  scroll-snap-margin-top: 4rem;
}

// /*airplay */

// select {
//   font-family: sans-serif;
// }

// body#radio_airplay_landing {
//   text-align: center;
//   color: #000;
//   padding: 0;
//   margin: 0;
//   font-family: arial, helvetica, sans-serif;
//   line-height: normal;
//   width: 100%;
//   background: #ebebeb url("~images/airplay_splash_bg_circles_1600x1027.jpg")
//     no-repeat fixed center top;
// }

// body#radio_airplay_landing #gutters {
//   position: relative;
//   margin: 0px auto 22px;
//   width: 960px;
//   border: 1px solid #dcdcdc;
//   border-width: 0px 1px 1px 1px;
// }

// body#radio_airplay_landing #page {
//   position: relative;
//   background: #fff;
//   width: 960px;
//   text-align: left;
// }

body {
  font-family: arial, helvetica, sans-serif;
}

a {
  text-decoration: none;
  color: #0095c3;
}

.brands_bg {
  background: #ececec;
  border: 1px solid #dcdcdc;
  border-width: 1px 0;
}

.testi_bg {
  background: #ececec;
}

#guaranteed_header {
  span {
    width: 100%;
  }
}
@media (min-width: 776px) {
  span {
    width: 70%;
  }
}

#header {
  color: #fff;
  font-size: 13px;
  text-align: center;

  //height: 50px;
  background: #0085ae;
  border-radius: 0px 0px 3px 3px;

  // height: 50px;
  // left: -10px;
  // overflow: hidden;
  //position: relative;
  text-align: center;
  //  top: -5px;
  //  width: 980px;
}

#header a {
  text-decoration: none;
  color: #fff;
}

#airplay_dash_logo {
  // width: 180px;
  aspect-ratio: 280/60;
  max-height: 60px;
}

// @media (min-width: 576px) {
//   #airplay_dash_logo {
//     width: 233px;
//   }
// }

@media (min-width: 992px) {
  .mx-lg-n2 {
    --bs-gutter-x: 1.5rem;

    margin-right: calc(-0.5 * var(--bs-gutter-x)) !important;
    margin-left: calc(-0.5 * var(--bs-gutter-x)) !important;
  }
}

// #header_right {
//   float: right;
//   width: 160px;
//   background: #0096c3;
//   height: 100%;
// }

// #partners {
//   height: 75px;
//   margin-top: 60px;
// }

#menu {
  // position: sticky;
  // margin-left: -10px;
  //  top: 1rem;
  // top: 12rem;
}

#header .dropdown-menu .dropdown-item a {
  color: #000;
}

#landing_menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-left: -10px;
  //top: 1rem;

  background-color: rgba(255, 255, 255, 0.7);
}

@media (min-width: 992px) {
  #menu {
    top: 9rem;
  }
}

// #landing_menu li {
//   float: left;
//   clear: both;
//   margin-bottom: 1px;

//   a {
//     color: #fff;
//     text-decoration: none;
//     display: block;
//     padding: 0;
//     margin: 0;
//     width: 170px;
//     height: 40px;
//     color: #fff;
//     font-size: 14px;
//     line-height: 40px;
//     text-align: right;

//     background: #747474;
//     border-radius: 3px;

//     transition: 0.3s all;
//     span {
//       font-family: "Open Sans", arial, sans;
//       transition: 0.3s all;
//       padding-right: 0.6rem;
//     }
//   }
// }

// #landing_menu li a.active span {
//   display: block;
//   cursor: pointer;
//   width: 180px;
//   font-weight: bold;
//   font-family: "Open Sans Bold", arial, sans;
// //  background: transparent url("~images/selected_menu_green_180x40.png");
//   filter: none;
// }

// #landing_menu li a.active span {
//   padding-right: 1.5rem;
//   cursor: pointer;
// }

// #landing_get_started_button {
//   // max-width: 38rem;
//   margin: 0px auto;
//   border: 1px solid #818384;
//   color: #ffffff;
//   font-size: 1.5rem; //24px;
//   height: auto;
//   // line-height: 28px;
//   padding: 1rem 2rem;
//   //  width: 135px;
//   transition: 0.4s all;
//   &:hover {
//     background-color: #b4e4cf;
//     color: #000;
//     border: 1px solid #fff;
//   }
// }

// .fr #landing_get_started_button {
//   font-size: 19px;
// }

.landing_green {
  text-align: center;
  display: block;
  background: #08a561;
  border-radius: 5px;
}


.cf:after {
  content: " ";
  display: block;
  height: 0px;
  clear: both;
  visibility: hidden;
}

.cf {
  display: inline-block;
}

#mp_foot_container {
  text-align: center;
  clear: both;
  margin: 40px auto;
  width: 329px;
  overflow: visible;
}

#mp_foot {
  list-style-type: none;
  position: relative;
  padding: 0;
  margin: 0;
}

#mp_foot li {
  float: left;
}

#mp_foot li a {
  color: #000;
}

#mp_foot li a:hover {
  color: #0099ff;
}

#landing_signup {
  background: #b4e9d2;
  position: relative;
  margin: 0px auto;
  max-width: 80%;
  border-radius: 0 0 5px 5px;
  transition: 0.5s all;

  a {
    color: #254c52;
  }

  &:hover {
    background-color: #08a561;
    a {
      color: #fff;
    }
  }
}

.landing_head {
  color: #000000;
  //  height: 90px;
  letter-spacing: -0.05em;
  line-height: 0.85;
  //  width: 480px;
  h1 {
    font-size: 3.2rem;
    max-width: 40rem;
  }
  h2 {
    //  margin:0;
    font-size: 2.5rem;
  }
}

// .landing_head.en {
// //  font-size: 46px;
//   h1 {
//     font-size: 3.2rem;
//   }
// }
.land.row {
  justify-content: center;
}

// #landing_main {
//   justify-content: center;
//   // margin-left: 0rem;
//   // position: relative;
//   // left: 220px;
//   // top: 50px;
//   //  z-index: 1;
// }

//@media (min-width: 576px) {
//   #landing_main {
//     margin-left: 8rem;
//   }
// }
@media (min-width: 992px) {
  #landing_main {
    margin-left: 14rem;
    margin-right: 6rem;
  }
  .land.row {
    justify-content: initial;
  }
}

.shadow-lg-light {
  box-shadow: 0 1rem 3rem rgba(255, 255, 255, 0.5) !important;
}

.link_putter {
  font-size: 16px;
}

.fr .link_putter {
  font-size: 13px;
}

// #landing_costs td {
//   padding: 12px 0px 12px 30px;
//   text-align: left;
//   font-size: 18px;
// }

// td.td_cost {
//   border-right: 1px solid #bababa;
//   width: 60px;
// }

// #docked_form {
//   border-radius: 5px 0 0 0;
//   width: 490px;
//   height: auto;
//   position: fixed;
//   bottom: 0px;
//   right: 0px;
//   background: lightblue;
//   padding: 20px;
//   z-index: 101;
// }
// #docked_form #new_band_form label {
//   text-align: left;
//   width: 160px;
//   float: left;
// }
// #docked_form #new_band_form input.text,
// #new_band_form input.password {
//   width: 228px;
//   float: left;
// }
// #docked_form ul {
//   padding: 0;
// }
// #docked_form li {
//   padding: 0px 20px;
// }
// #docked_form input[type="email"],
// #docked_form input[type="password"],
// #docked_form input[type="text"] {
//   border-radius: 3px;
//   border: 1px solid #dcdcdc;
//   padding: 3px;
// }
// #docked_form h2 {
//   cursor: pointer;
// }
// #docked_form_lapse {
//   padding: 0 36px 0 16px;
// }
// #docked_form_lapse:after {
//   content: "\2013"; /* &ndash;" */
// }
// #docked_form_lapse.collapsed:after {
//   content: "\002b"; /* &plus;" */
// }
//
//


