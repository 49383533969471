@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  src: url("~fonts/OpenSans/OpenSans-Regular.woff2") format("woff2"),
    url("~fonts/OpenSans/OpenSans-Regular.woff") format("woff");
}

@font-face {
  font-family: "Open Sans ExtraBold";
  font-style: normal;
  font-weight: normal;
  src: url("~fonts/OpenSans/OpenSans-ExtraBold.woff2") format("woff2"),
    url("~fonts/OpenSans/OpenSans-ExtraBold.woff") format("woff");
}

@font-face {
  font-family: "Open Sans Bold";
  font-style: normal;
  font-weight: normal;
  src: url("~fonts/OpenSans/OpenSans-Bold.woff2") format("woff2"),
    url("~fonts/OpenSans/OpenSans-Bold.woff") format("woff");
}

.open_sans {
  font-family: "Open Sans", sans-serif, arial !important;
}
.open_sans_bold {
  font-family: "Open Sans Bold", sans-serif, arial !important;
}
.open_sans_extra_bold {
  font-family: "Open Sans ExtraBold", sans-serif, arial;
}
