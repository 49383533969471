
// $primary: #0095c3;
// $link-decoration: none;
// $enable-negative-margins: true;

$body-bg: #e5e5e5;
$body-color: #111;
$primary: #0095c3;
$link-decoration: none;
$enable-negative-margins: true;
$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 3.5,
  10: $spacer * 6,
);


//@import "~bootstrap5/scss/~bootstrap5/scss/bootstrap";

$form-check-input-bg:  #fff;

$grid-breakpoints: (
  xs: 0,
  xsm: 374px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);


@import "~bootstrap5/scss/mixins/banner";
@include bsBanner("");


// scss-docs-start import-stack
// Configuration
@import "~bootstrap5/scss/functions";
@import "~bootstrap5/scss/variables";
@import "~bootstrap5/scss/variables-dark";
@import "~bootstrap5/scss/maps";
@import "~bootstrap5/scss/mixins";
@import "~bootstrap5/scss/utilities";

$form-range-track-width:          100%;
$form-range-track-height:         .7rem;
$form-range-track-cursor:         pointer;
$form-range-track-bg:             $gray-500;
$form-range-track-border-radius:  1rem;
$form-range-track-box-shadow:     $box-shadow-inset;

$form-range-thumb-width:                   2rem;
$form-range-thumb-height:                  $form-range-thumb-width;
$form-range-thumb-bg:                      $white;
$form-range-thumb-border:                  2px solid $primary;
$form-range-thumb-border-radius:           2rem;
$form-range-thumb-box-shadow:              0 .1rem .25rem rgba($black, .1);
$form-range-thumb-focus-box-shadow:        0 0 0 1px $body-bg, $input-focus-box-shadow;
$form-range-thumb-focus-box-shadow-width:  $input-focus-width; // For focus box shadow issue in Edge
$form-range-thumb-active-bg:               tint-color($component-active-bg, 70%);
$form-range-thumb-disabled-bg:             $gray-300;
$form-range-thumb-transition:              background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;


// Layout & components
@import "~bootstrap5/scss/root";
@import "~bootstrap5/scss/reboot";
@import "~bootstrap5/scss/type";
@import "~bootstrap5/scss/images";
@import "~bootstrap5/scss/containers";
@import "~bootstrap5/scss/grid";
@import "~bootstrap5/scss/tables";
@import "~bootstrap5/scss/forms";
@import "~bootstrap5/scss/buttons";
@import "~bootstrap5/scss/transitions";
@import "~bootstrap5/scss/dropdown";
@import "~bootstrap5/scss/button-group";
@import "~bootstrap5/scss/nav";
@import "~bootstrap5/scss/navbar";
@import "~bootstrap5/scss/card";
@import "~bootstrap5/scss/accordion";
@import "~bootstrap5/scss/breadcrumb";
@import "~bootstrap5/scss/pagination";
@import "~bootstrap5/scss/badge";
@import "~bootstrap5/scss/alert";
@import "~bootstrap5/scss/progress";
@import "~bootstrap5/scss/list-group";
@import "~bootstrap5/scss/close";
@import "~bootstrap5/scss/toasts";
@import "~bootstrap5/scss/modal";
@import "~bootstrap5/scss/tooltip";
@import "~bootstrap5/scss/popover";
@import "~bootstrap5/scss/carousel";
@import "~bootstrap5/scss/spinners";
@import "~bootstrap5/scss/offcanvas";
@import "~bootstrap5/scss/placeholders";

// Helpers
@import "~bootstrap5/scss/helpers";

// Utilities
@import "~bootstrap5/scss/utilities/api";
// scss-docs-end import-stack



