.tier-card {
  .card-header {
    background-color: #fff !important;
    &.free {
      color: #c2c0c1;
    }
    &.intro {
      color: #773573;
    }
    &.gold {
      color: #bc9e2e;
    }
    &.platinum {
      color: #007ca2;
    }
    &.multi-platinum {
      color: #387286;
    }
  }

  button {
    &.free {
      background: #c2c0c1;
      &:hover {
        background-color: rgba(56, 114, 134, 0.8);
      }
    }
    &.intro {
      background: #773573;
      &:hover {
        background-color: rgba(119, 53, 115, 0.8);
      }
    }
    &.gold {
      background: #bc9e2e;
      &:hover {
        background-color: rgb(188, 158, 46, 0.8);
      }
    }
    &.platinum {
      background: #007ca2;
      &:hover {
        background-color: rgb(0, 124, 162, 0.8);
      }
    }
    &.multi-platinum {
      background-color: #387286;
      &:hover {
        background-color: rgba(56, 114, 134, 0.8);
      }
    }
  }
}

.plans {
  .switch-wrapper {
    position: relative;
    display: inline-flex;
    padding: 4px;
    border: 1px solid lightgrey;
    border-radius: 30px;
    background: #fff;
  }

  .switch-wrapper [type="radio"] {
    position: absolute;
    left: -9999px;
  }

  .switch-wrapper [type="radio"]:checked#monthly ~ label[for="monthly"],
  .switch-wrapper [type="radio"]:checked#yearly ~ label[for="yearly"] {
    color: #fff;
  }

  .switch-wrapper [type="radio"]:checked#monthly ~ label[for="monthly"]:hover,
  .switch-wrapper [type="radio"]:checked#yearly ~ label[for="yearly"]:hover {
    background: transparent;
  }

  .switch-wrapper
    [type="radio"]:checked#monthly
    + label[for="yearly"]
    ~ .highlighter {
    transform: none;
  }

  .switch-wrapper
    [type="radio"]:checked#yearly
    + label[for="monthly"]
    ~ .highlighter {
    transform: translateX(100%);
    background: #228c22;
  }

  .switch-wrapper label {
    font-size: 1.2rem;
    z-index: 1;
    min-width: 120px;
    line-height: 46px;
    cursor: pointer;
    border-radius: 30px;
    transition: color 0.25s ease-in-out;
  }

  .switch-wrapper label:hover {
    background: #efefef;
  }

  .switch-wrapper .highlighter {
    position: absolute;
    top: 4px;
    left: 4px;
    width: calc(50% - 4px);
    height: calc(100% - 8px);
    border-radius: 30px;
    background: #228c22;
    transition: all 0.5s ease-in-out;
  }

  .fs-7 {
    font-size: 0.8rem;
  }

  [data-plans-term-value="month"] {
    .year_copy {
      display: none;
    }
    .year_copy_vis {
      transition: all 0.1s;
      opacity: 0;
    }
  }
  [data-plans-term-value="year"] {
    .month_copy {
      display: none;
    }
    .year_copy_vis {
      transition: all 0.3s;
      opacity: 1;
    }
  }
}

@media (min-width: 776px) {
h1.pricing-card-title{
  small{
      font-size: 1.5rem;

  }
}
}

#pricing_tiers {
  border-collapse: collapse;
  clear: both;
  margin: 0px auto 10px;
  text-align: center;
  font-family: "helvetica neue", helvetica, arial, sans-serif;
  transform: scale(96%);
}
@media (min-width: 776px) {
  #pricing_tiers {
    transform: scale(100%);
  }
}
#pricing_tiers td.hover {
  background: #dcdcdc;
  transition: background-color 0.5s;
}
#pricing_tiers td.current {
  background: #ece0b4;
}
#pricing_tiers tr {
  vertical-align: middle;
}
#pricing_tiers colgroup.current {
  background: none;
}
#pricing_tiers tr td {
  background: none;
}
#pricing_tiers tr td.intro.hover,
#pricing_tiers tr td.intro.current {
  background: #f8eef7;
}
#pricing_tiers tr td.gold.hover,
#pricing_tiers tr td.gold.current {
  background: #ece0b4;
}
#pricing_tiers tr td.platinum.hover,
#pricing_tiers tr td.platinum.current {
  background: #ddf7ff;
}
#pricing_tiers tr td.multi-platinum.hover,
#pricing_tiers tr td.multi-platinum.current {
  background: #dfedf2;
}
#pricing_tiers tr td.diamond.hover,
#pricing_tiers tr td.diamond.current {
  background: #cef3ee;
}

#pricing_tiers tr td.name {
  font-weight: bold;
  color: #fff;
}
#pricing_tiers tr td.name.free {
  background: #c2c0c1;
  text-transform: capitalize;
}
#pricing_tiers tr td.name.intro {
  background: #773573;
  text-transform: uppercase;
}
#pricing_tiers tr td.name.gold {
  background: #bc9e2e;
  text-transform: uppercase;
}
#pricing_tiers tr td.name.platinum {
  background: #007ca2;
  text-transform: uppercase;
}
#pricing_tiers tr td.name.multi-platinum {
  background: #387286;
  text-transform: uppercase;
}
#pricing_tiers tr td.name.diamond {
  background: #2ebbaa;
  text-transform: uppercase;
}
#pricing_tiers tr td.row_name {
  background: #a5e6c7;
  text-align: left;
  /*    min-width: 158px;*/
  /*    font-size:16px;*/
  /*    padding: 8px 3px 8px 12px;*/
  font-weight: bold;
}

#pricing_tiers {
  font-size: 16px;
}

/*    @media(min-width: 776px){
      #pricing_tiers{font-size: 14px}
    }*/
#pricing_tiers tr td.plays {
  font-weight: bold;
}
#pricing_tiers tr td.bonus {
  font-style: italic;
  font-weight: bold;
}
#pricing_tiers tr td.main {
  border: 1px solid #999;
}
#pricing_tiers tr td a.select_button {
  margin: 0px auto;
  color: #fff;
}
#pricing_tiers tr td a.select_button {
  white-space: nowrap;
}
#pricing_tiers tr td a.select_button.free {
  background: #c2c0c1 !important;
}

#pricing_tiers tr td a.select_button.intro {
  background: #773573 !important;
}
#pricing_tiers tr td a.select_button.gold {
  background: #bc9e2e !important;
}
#pricing_tiers tr td a.select_button.platinum {
  background: #007ca2 !important;
}
#pricing_tiers tr td a.select_button.multi-platinum {
  background: #387286 !important;
}
#pricing_tiers tr td a.select_button.diamond {
  background: #2ebbaa !important;
}
#pricing_tiers tr td a.select_button:hover {
  color: #000;
  transition: color 0.5s, transform 2s;
}
#pricing_tiers tr td a.select_button svg {
  vertical-align: text-top;
}
#pricing_tiers tr#below_cells td {
  padding: 7px 2px;
  border: 0;
}
#pricing_tiers tr#above_headers {
  border-bottom: 1px solid #999;
}
#pricing_tiers tr#above_headers td {
  padding: 0;
}
#pricing_tiers tr#above_headers td.hover,
#pricing_tiers tr#below_cells td.hover,
#pricing_tiers tr#above_headers td.current,
#pricing_tiers tr#below_cells td.current {
  background: none;
}
#pricing_tiers tr td.hidden {
  display: none;
}
#pricing_tiers tr#above_headers td.platinum div {
  margin: 0 -1px 0 -1px;
  border-radius: 5px 5px 0 0;
  background: #ddf7ff;
  padding: 7px 0;
  border: 1px solid #999;
  border-width: 1px 1px 0px 1px;
}
#pricing_tiers tr#above_headers td.platinum.current div {
  background: none;
  padding: 7px 0;
  border: 0;
}
#pricing_tiers tr#above_headers td.gold div {
  margin: 0 -1px 0 -1px;
  border-radius: 5px 5px 0 0;
  background: #ece0b4;
  padding: 7px 0;
  border: 1px solid #999;
  border-width: 1px 1px 0px 1px;
}
#pricing_tiers tr#above_headers td.gold.current div {
  background: none;
  padding: 7px 0;
  border: 0;
}
