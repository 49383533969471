// $body-bg: #e5e5e5;
// $body-color: #111;
// $primary: #0095c3;
// $link-decoration: none;
// $enable-negative-margins: true;
// $spacer: 1rem;
// $spacers: (
//   0: 0,
//   1: $spacer * 0.25,
//   2: $spacer * 0.5,
//   3: $spacer,
//   4: $spacer * 1.5,
//   5: $spacer * 3,
//   6: $spacer * 3.5,
//   10: $spacer * 6,
// );

//@import "~bootstrap5/scss/bootstrap";

@import "../../stylesheets/fonts";
@import "../../stylesheets/radio_airplay/pricing_tiers";

@import "../../stylesheets/radio_airplay_landing";


@import "../../stylesheets/radio_airplay/custom_bootstrap";


.bg-trans-darkened{background: rgba(0,0,0,0.05);}
.bg-trans-lightened{background: rgba(255,255,255,0.05);}


.bg-lt-bluish {
  background-color: #0195c3;
}
.text-lt-bluish {
  color: #0195c3;
}
// .py-10 {
//   padding-top: 6rem;
//   padding-bottom: 6rem;
// }
// .pt-10 {
//   padding-top: 6rem;
// }
.ease-in-out {
  transition: all 0.6s ease-in-out !important;
}
.bg-dk-bluish {
  background-color: #207089;
}

.btn-primary {
  color: #fff;
}

.sp-header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
}

.splash_container {
  background: rgb(36, 36, 36);
  color: #fff;
}

@media (min-width: 992px) {
  .align-self-start-lg {
    align-self: start !important;
  }
}

.disc-list {
  list-style-type: disc;
  li {
    margin: 0.5rem 0;
  }
}
// .ra-splash {
//   font-size: 1.1rem;
//   .btn {
//     font-size: 1.4rem;
//   }
// }
.btn-custom {
  --bs-btn-bg: #000;
  --bs-btn-padding-y: 0.15rem;
  --bs-btn-padding-x: 1.5rem;
}
a .btn-custom-log {
  --bs-btn-padding-y: 0.15rem;
  --bs-btn-padding-x: 1.5rem;
}

.blue_highlight {
  color: rgb(140, 211, 233);
}
.blue_highlight_bg {
  background: rgb(140, 211, 233);
}

@font-face {
  font-family: "Horizon";
  font-style: normal;
  font-weight: normal;
  src: url("~fonts/Horizon/horizon.woff") format("woff");
}

.horizon {
  font-family: "Horizon";
}
// .h-16 {
//   height: 16% !important;
// }

.navbar-light {
  button.navbar-toggler {
    border-color: rgba(255, 255, 255, 0.8);

    &:focus {
      box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width)
        rgba(255, 255, 255, 0.6);
    }

    span.navbar-toggler-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(253, 255, 255, 1)' stroke-width='3' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    }
  }
}

.mh-24rem {
  min-height: 24rem;
}

.bg-lightblue {
  background-color: #e1f5fe;
}

#splash_logo {
  max-height: 86px;
  @media (min-width: 576px) {
    max-height: 112px;
  }
}
.lead_image {
    
  @media (max-width: 576px) {
    min-height: 26rem;
    // max-width: 200%;
     object-fit: cover;
    object-position: center;
//   transform: translateX(-50%);
  }
//     @media (max-width: 576px) {
//          transform: translateX(-35%);

// }
}
#airplay_dash_logo {
  // width: 180px;
  aspect-ratio: 280/60;
  //max-height: 46px;
  //}
  //@media (min-width: 576px) {
  // #airplay_dash_logo {
  width: auto;
  max-height: 56px;

  //  }
}

@media (max-width: 375px) {
  #airplay_dash_logo {
    max-height: 42px;
  }
}
// @media (max-width: 340px) {
//   #airplay_dash_logo {
//     max-height: 35px;
//   }
// }
//splash
.guaranteed_airplay {
  body {
    --bs-bg-opacity: 1;
    background-color: rgba(33, 37, 41, var(--bs-bg-opacity)) !important;
    background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
  }
  // img.op-0 {
  //   opacity: 0;
  //   transition: 0.5s all ;
  // }
  // img.op {
  //   opacity: 1;
  // }
  // @media (max-width: 576px) {
  //   #airplay_dash_logo {
  //     max-width: 50%;
  //   }
  // }


  .foreground {
    z-index: 99;
    position: absolute;
    left: 0%;
    top: 0%;
    background: transparent;
    width: 100%;
    height: 100%;

    h1 {
      font-family: "Horizon", sans-serif;

      font-size: 4.4vw;
      @media (max-width: 576px) {
        font-size: 8vw;
      }
      font-weight: bold;
      //  line-height: 5.9vw;
      text-shadow: 5px 5px 6px rgba(0, 0, 0, 0.6);
      /*  filter: blur(0.8px);*/
      color: #faf9f6;
    }
    #lead {
      line-height: 4.4vw;
      position: absolute;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 60%;
    }
  }
  h2 {
    font-family: "Horizon", sans-serif;

    font-size: 2.4rem;
    font-weight: bold;

    @media (max-width: 768px) {
      &.lead_header {
        font-size: 9vw;
      }
    }
  }
  h3.blue_highlight {
    font-family: "Horizon", sans-serif;

    //font-size: 1rem;
    font-weight: bold;
  }
  h4.blue_highlight {
    font-family: "Horizon", sans-serif;

    font-size: 1.2rem;
    font-weight: bold;
  }
  .odd {
    background: rgb(2, 11, 163);
  }
  .even {
    background: rgb(136, 45, 74);
  }
  .logo {
    width: 233px;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 21px;
  }
}
